<template>
  <RepairLayout>
    <div>Back repair home page</div>
  </RepairLayout>
</template>

<script setup lang="ts">
import { useHead } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'

import translations from './RepairTracking.translations'
import RepairLayout from './layouts/RepairLayout.vue'

const i18n = useI18n()

useHead({
  title: i18n(translations.pageTitle),
  htmlAttrs: { lang: useI18nLocale() },
})
</script>

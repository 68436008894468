<template>
  <RevButtonBase
    :aria-label="COMPANY_NAME"
    class="h-12 md:h-16 lg:h-20"
    :to="resolveLocalizedRoute({ name: HOME })"
    :tracking="{ name: 'logo', zone: 'header' }"
  >
    <RevIllustration
      :alt="COMPANY_NAME"
      :height="24"
      src="/img/header/Logo.svg"
      :width="180"
    />
  </RevButtonBase>
</template>

<script setup>
import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'

import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import { HOME } from '~/scopes/home/route-names'

const runtimeConfig = useRuntimeConfig()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const { COMPANY_NAME } = runtimeConfig.public
</script>
